@import "../../../styles/colors.scss";
@import "../../../styles/fontSizes.scss";

.data-bars-item {
    position: relative;

    strong {
        font-size: $medium;
        font-weight: 600;
    }

    .bar-out {
        background: darken($blue-light, 3);
        border-radius: 10px;
        display: block;
        height: 10px;
        margin: 16px 0;
        position: relative;
        width: 100%;

        .bar-in {
            background: $blue;
            border-radius: 10px;
            height: 100%;
            position: absolute;
            width: 50%;
        }
    }

    footer {
        display: flex;
        justify-content: space-between;

        small {
            color: $blue-grey;
            font-size: $medium;
            font-weight: 600;
        }
    }
}