@import "../../styles/colors.scss";
@import "../../styles/fontSizes.scss";

.data-bars-container {
    &.--dark{
        background: $dark;
        box-shadow: none;
        border: none;

        .demand-list {
            .title {
                color: $whitish;

            }
        }
    }
    background: $whitish;
    box-sizing: border-box;
    border: 3px solid $whitish;
    border-radius: 6px;
    box-shadow: 0 5px 10px rgba(lighten($blue-grey, 10), 0.2);
    overflow: hidden;
    padding: 40px;

    @media all and (min-width: 1500px) {
        min-width: 425px;
    }

    .demand-list {
        border-bottom: 1px solid darken($blue-light, 4);
        padding: 32px 0;

        &:first-of-type {
            padding-top: 0;
        }

        &:last-of-type {
            border-bottom: 0;
            padding-bottom: 0;
        }

        .title {
            color: $blue;
            display: block;
            font-size: $big;
            font-weight: 600;
            margin-bottom: 32px;
        }

        .data-bars-item {
            padding-bottom: 24px;
            &:last-of-type{
                padding-bottom: 0;
            }
        }
    }

    footer {
        button {
            border-radius: 32px;
            display: block;
            margin-top: 40px;
            padding: 16px;
            width: 100%;
        }
    }
}