@import '../../../styles/colors.scss';
@import "../../../styles/fontSizes.scss";

.login-page{
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;

    .remind{
        max-width: 600px;
        align-items: center;
        background: #FFF;
        border-radius: 8px;
        box-shadow: 0 20px 30px 0 rgba(141,161,193,0.62);
        display: flex;
        flex-flow: column;
        padding: 80px 112px;

        img{
            margin: 0 auto 56px;;
            max-width: 200px;
            width: 100%;
        }

        h6{
            color: $blue;
            display: block;
            font-size: $xbig;
            font-weight: 500;
            text-align: center;
            margin-bottom: 60px;
        }

        .go-to-login {
            margin-top: 30px;
        }
    }
    .error{
        margin-bottom: 16px;
        display: flex;
        justify-content: center;
        background: rgba(238,63,29,0.2);
        line-height: 3;
    }
}
