@import "../../styles/colors.scss";
@import "../../styles/fontSizes.scss";

.main-header {
    background: $whitish;
    border-bottom: 1px solid darken($blue-light, 4);
    position: relative;
    top: 0;
    left: 0;
    height: 75px;
    width: 100%;
    z-index: 30;
    &.--dark {
        background: $darkest;
        border-bottom-color: $dark-alt;
        .links {
            a {
                color: $whitish;
            }
            &:hover, &.active {
                color: $whitish;
                a {
                    color: $blue-alt;
                }
            }
        }
    }
    .navigation {
        align-items: center;
        display: flex;
        height: 100%;

        .logo a {
            display: flex;
            margin-right: 56px;
            
            img{
                height: 32px;
                width: auto;

                &:nth-of-type(1){
                    margin-right: 24px;
                }
            }
            @media all and (max-width: 700px) {
                margin-right: 24px;
                img {
                    height: 20px;
                }
            }
        }

        ul {
            align-items: center;
            display: flex;
            height: 100%;
            justify-content: space-around;
            width: 100%;
            min-width: 200px;
        }
    }

    .links {
        align-items: center;
        display: flex;
        height: 100%;
        list-style: none;
        position: relative;
        
        &:after {
            bottom: 0;
            content: '';
            background: $blue;
            height: 3px;
            position: absolute;
            transition: width 0.35s cubic-bezier(1, 0.01, 0, 1.22);
            width: 0;
        }
        &:last-of-type{
            margin-right: 15px;
        }

        a {
            color: $blue-grey;
            color: lighten($blue-grey, 10);
            font-size: $medium;
            font-weight: 600;
            height: 100%;
            line-height: 75px;
            text-decoration: none;
            transition: color 0.35s cubic-bezier(1, 0.01, 0, 1.22);
        }

        &:hover, &.active {
            &:after {
                width: 100%;
            }

        }

        @media all and (max-width: 700px) {
            margin-right: 40px;
            a{
                font-size: 14px;
                line-height: 60px;
            }
        }
    }


   .sd-actions {
        align-items: center;
        display: flex;
        height: 100%;

        .icon-button{
            position: relative;

            &:after{
                background: #FFF;
                border: 1px solid #e3e9f3;
                bottom: 0;
                box-sizing: border-box;
                border-radius: 4px;
                content: 'Modo oscuro';
                display: block;
                font-size: 12px;
                left: 50%;
                opacity: 0;
                padding: 4px;
                position: absolute;
                text-align: center;
                transform: translate(-50%, 120%);
                transition: opacity 0.35s ease-in, visibility 0.35s ease-in;
                width: fit-content;
                height: fit-content;
                visibility: hidden;
            }

            &:hover{
                &:after{
                    opacity: 1;
                    transition-delay: 0.15s;
                    visibility: visible;
                }
            }
        }

        .icon-button:first-of-type{
            margin-right: 16px;

            &:after{
                content: 'Aumentar tamaño';
            }
        }

        .text-button {
            margin-left: 32px;

            @media all and (max-width: 700px) {
                margin-left: 24px;
                font-size: $small;
                display: none;
            }
        }

        .icon-button {
            @media all and (max-width: 700px) {
                display: none;
            }
        }
    }

    @media all and (max-width: 700px) {
        height: 60px;
    }
}
