@import '../../../styles/colors.scss';
@import "../../../styles/fontSizes.scss";

.login-page{
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;

    .login{
        align-items: center;
        background: #FFF;
        border-radius: 8px;
        box-shadow: 0 20px 30px 0 rgba(141,161,193,0.62);
        display: flex;
        flex-flow: column;
        padding: 80px 112px;

        img{
            margin: 0 auto 56px;;
            max-width: 200px;
            width: 100%;
        }

        h6{
            color: $blue;
            display: block;
            font-size: $xbig;
            font-weight: 500;
            text-align: center;
            margin-bottom: 80px;
        }

        .login-form{
            display: flex;
            flex-flow: column;
            width: 100%;

            .input-item{
                margin-bottom: 48px;
                position: relative;

                svg{
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 16px;
                }
            }

            input{
                background: transparent;
                border: none;
                border-bottom: 2px solid $blue;
                box-sizing: border-box;
                font-size: $medium;
                padding: 16px 16px 16px 56px;
                min-width: 380px;
                width: 100%;
            }

            button{
                background: $blue;
                border-radius: 50px;
                color: #FFF;
                cursor: pointer;
                font-size: $small;
                font-weight: 600;
                padding: 16px 0;
                width: 100%;
            }

            a{
                display: block;
                text-align: center;
                padding-top: 24px;
            }
        }
    }
    .error{
        margin-bottom: 16px;
        display: flex;
        justify-content: center;
        background: rgba(238,63,29,0.2);
        line-height: 3;
    }
}
