@import "../../styles/colors.scss";
@import "../../styles/fontSizes.scss";

.listado {
  display: flex;
  justify-content: space-between;
  padding-top: 56px;

  @media all and (max-width: 1150px) {
    padding-top: 30px;
  }

  .categories-container {
    width: 21%;
  }

  header {
    .title {
      flex: 1;
    }
  }

  .top-manager {
    display: flex;
    justify-content: space-between;
    flex: 0.5;

    .icons-view-type {
      display: flex;
      justify-content: space-between;
      .icon {
        cursor: pointer;
        padding-left: 15px;
        height: 16px;
        position: relative;
        &:after {
          background: #fff;
          border: 1px solid #e3e9f3;
          bottom: 0;
          box-sizing: border-box;
          border-radius: 4px;
          content: "Vista cuadrícula";
          font-size: 12px;
          display: block;
          left: 50%;
          opacity: 0;
          padding: 4px;
          position: absolute;
          transform: translate(-50%, 120%);
          transition: opacity 0.35s ease-in, visibility 0.35s ease-in;
          text-align: center;
          width: fit-content;
          height: fit-content;
          visibility: hidden;
        }

        &:hover {
          &:after {
            opacity: 1;
            transition-delay: 0.15s;
            visibility: visible;
          }
        }

        &:last-of-type {
          &:after {
            content: "Vista listado";
          }
        }
      }

      &.--dark {
        .icon {
          &:after {
            color: $black;
          }
        }
      }
    }
  }

  header {
    display: flex;
    justify-content: space-between;
  }

  .title.small {
    display: inline-flex !important;
    font-size: 16px !important;

    span {
      color: $blue;
      position: relative;

      img {
        display: inline-block;
        padding-left: 8px;
        vertical-align: middle;
      }

      ul {
        background: #fff;
        border-radius: 6px;
        box-shadow: 0 5px 10px rgba(lighten($blue-grey, 10), 0.2);
        padding: 0px;
        position: absolute;
        left: 0;
        max-height: 0;
        overflow: hidden;
        transform: translateY(16px);
        transition: max-height 0.35s ease-in;
        width: 150%;
        z-index: 2;

        li {
          border-bottom: 1px solid rgba($blue-grey, 0.2);
          cursor: pointer;
          font-size: $medium;
          list-style: none;
          padding: 16px;
          transition: background-color 0.25s ease-in;

          &:last-of-type {
            border-bottom: 0;
          }

          &:hover {
            background-color: $blue-light;
          }
        }
      }

      &:hover {
        ul {
          max-height: 300px;
        }
      }
    }
  }

  .area-center {
    flex: 1;
  }

  .area-center,
  .area-right {
    strong.title {
      display: block;
      font-size: $big;
      font-weight: 600;
      margin-bottom: 40px;

      span {
        cursor: pointer;
        display: inline-block;
        position: relative;
        padding-left: 8px;
      }
    }
  }

  .area-right {
    .title.small {
      display: inline-flex;
      font-size: $medium;

      span {
        color: $blue;
        position: relative;

        img {
          display: inline-block;
          padding-left: 8px;
          vertical-align: middle;
        }

        ul {
          background: #fff;
          border-radius: 6px;
          box-shadow: 0 5px 10px rgba(lighten($blue-grey, 10), 0.2);
          padding: 0px;
          position: absolute;
          left: 0;
          max-height: 0;
          overflow: hidden;
          transform: translateY(16px);
          transition: max-height 0.35s ease-in;
          width: 150%;
          z-index: 2;

          li {
            border-bottom: 1px solid rgba($blue-grey, 0.2);
            cursor: pointer;
            font-size: $medium;
            list-style: none;
            padding: 16px;
            transition: background-color 0.25s ease-in;

            &:last-of-type {
              border-bottom: 0;
            }

            &:hover {
              background-color: $blue-light;
            }
          }
        }

        &:hover {
          ul {
            max-height: 300px;
          }
        }
      }
    }
  }
}
