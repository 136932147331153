@import "../../styles/colors.scss";

.map-selector {
    &.--dark{
        background: $darker;
        border: 3px solid $darker;   
        box-shadow: none;
        .map-wr {
            path {
                &.selected {
                    fill: $whitish;
                }
            }
        }
    }
    background: $whitish;
    border: 3px solid $whitish;
    border-radius: 6px;
    box-shadow: 0 5px 10px rgba(lighten($blue-grey, 10), 0.2);
    
    overflow: hidden;

    @media all and (min-width: 700px) {
        max-width: 500px;
    }

    @media all and (min-width: 1200px) {
        max-width: 600px;
    }

    @media all and (min-width: 1500px) {
        max-width: 710px;
    }
 
}

.map-wr {
    padding: 60px 0 0 0;
    overflow: hidden;

    g {
        transform: translateX(-90px);
    }

    path {
        cursor: pointer;
        transition: fill 0.35s ease-in;
        &:hover {
            fill: #044deb8f;
        }
        
        &.selected {
            fill: $blue;
        }
    }
}

#map-svg{
    transition: transform 0.25s ease-in;
}