$whitish: #FFF;
$black: #031B4E;
$blue: #044DEB;
$blue-light: #F1F4F9;
$blue-grey: #717680;
$blue-alt: #8390ab;


$darkest: #010e2b;
$darker: #051434;
$dark: #041945;
$dark-alt: #0f1f42


