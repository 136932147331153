.container {
    box-sizing: border-box;
    height: 100%;
    margin: 0 auto;
    max-width: 1660px;
    padding: 0 20px;
    width: 100%;

    &.flex{
        display: flex;
    }

    &.v-center{
        align-items: center;
    }

    &.h-center{
        justify-content: center;
    }

    &.between{
        justify-content: space-between;
    }
}
