@import '../../styles/colors.scss';
@import "../../styles/fontSizes.scss";

.cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

    .load-more {
        background: none;
        border: none;
        color: $blue;
        font-size: $medium;
        font-weight: 500;
        margin-top: 30px;
      }
}