@import "../../../../styles/colors.scss";
@import "../../../../styles/fontSizes.scss";

.category-check-item {
    align-items: center;
    display: flex;
    list-style: none;
    &:not(:last-child) {
        padding-bottom: 32px;
    }
    
    label {
        color: $blue-grey;
        cursor: pointer;
        font-size: $medium;
        font-weight: 500;
        padding-left: 16px;
    }

    input{
        -moz-appearance:none;
        -webkit-appearance:none;
        -o-appearance:none;
        background: #FFF;
        border: 2px solid $blue;
        border-radius: 4px;
        cursor: pointer;
        height: 24px;
        transition: background 0.15s ease-in;
        position: relative;
        width: 24px;

        &:after{
            background: #FFF;
            content: '';
            height: 2px;
            opacity: 0;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-30%, -50%) rotate(-45deg);
            transition: opacity 0.25s ease-in;
            width: 70%;
        }

        &:before{
            background: #FFF;
            content: '';
            height: 2px;
            opacity: 0;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-100%, 50%) rotate(45deg);
            transition: opacity 0.25s ease-in;
            width: 30%;
        }

        &:focus{
            border-radius: 4px;
            box-shadow: inset 0px 0px 0px 4px $blue;
            transition: box-shadow 0.35s ease-in;
            outline: none;
        }

        &:checked{
            background: $blue;
            &:after, &:before{
                opacity: 1;
                transition-delay: 0.15s;
            }
        }
    }
}
