@import "../../styles/colors.scss";
@import "../../styles/fontSizes.scss";

.card-item {
  animation: cardItemAppear 0.35s ease-in forwards;
  opacity: 0;
  transform: translateY(24px);

  @for $i from 0 to 20 {
    &:nth-of-type(#{$i + 1}) {
      animation-delay: #{$i * 0.15s};
    }
  }

  @keyframes cardItemAppear {
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  background: $whitish;
  box-sizing: border-box;
  box-shadow: 0 2px 4px 0 $blue-light;
  border-radius: 6px;
  cursor: pointer;

  padding: 24px;
  transition: box-shadow 0.35s ease-in;
  margin-bottom: 40px;
  min-width: 260px;
  max-width: 260px;

  .content {
    align-items: center;
    display: flex;
    flex-flow: column;
    justify-content: center;
  }

  img {
    border-radius: 50%;
    width: 75px;
  }

  h5 {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    margin-top: 16px;
  }

  small {
    color: #b0b7c5;
    font-size: $small;
    font-weight: 600;
    padding-top: 4px;
    padding: 4px 4px 0 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 90%;
    text-transform: capitalize;
  }

  h6 {
    background: #e5e9f2;
    border-radius: 6px;
    box-sizing: border-box;
    color: $blue-alt;
    font-size: $medium;
    font-weight: 500;
    padding: 16px;
    text-align: center;
    margin-top: 16px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  footer {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    width: 100%;
    span {
      color: #b0b7c5;
      font-size: $small;
      font-weight: 500;
    }
  }

  &:hover {
    box-shadow: 0 20px 30px 0 rgba(141, 161, 193, 0.62);
  }

  &.list-view {
    display: flex;
    flex-flow: row;
    margin-bottom: 2px;
    max-width: none;
    width: 100%;

    .content {
      justify-content: space-between;
      flex-flow: row;
      width: 100%;

      img {
        width: 60px;
      }

      h5 {
        text-align: left;
        font-size: $medium;
        width: 30%;
      }

      small {
        display: none;
      }

      h6 {
        background: none;
        color: $black;
        text-align: left;
        width: 30%;
      }

      footer {
        width: 30%;

        span {
          color: $black;
          font-size: $medium;
        }
      }
    }
  }

  &.--closed {
    .closed {
      position: absolute;
      top: 0;
      left: 0;
      padding: 5px;
      font-size: 13px;
      font-style: italic;
      background: #dedede;
    }
  }
  &.--closed,
  &.--dark {
    .closed {
      color: $black;
    }
  }

  &.--dark {
    background: $dark;
    box-shadow: none;
    .content {
      small {
        color: $whitish;
      }
      h5 {
        color: $whitish;
      }
      h6 {
        background: $darker;
        color: $whitish;
      }
      footer {
        span {
          color: $whitish;
        }
      }
    }
  }
}
