@import "../../styles/colors.scss";
@import "../../styles/fontSizes.scss";

.sub-header {
  &.--dark {
    background: $darker;
    box-shadow: 0 2px 4px 0 #020918;
    border-bottom: none;
    .date-range {
      color: $blue-alt;
    }
    .subheader-actions button:first-child{
      border-left: 1px solid $dark-alt;
      border-right: 1px solid $dark-alt;
    }
    .subheader-actions button:last-child{
      border-right: 1px solid $dark-alt;
    }
    .subheader-date-range-item {
      &:hover,
      &.active {
        a,
        div {
          color: $whitish;
        }
      }
    }
  }
  background: $whitish;
  border-bottom: 1px solid darken($blue-light, 4);
  box-shadow: 0 2px 4px 0 #e5e9f2;
  position: sticky;
  top: 0;
  left: 0;
  height: 60px;
  width: 100%;
  z-index: 9;

  .hidden {
    .logo {
      opacity: 0;
      transform: translateY(-100%);
      transition: transform 0.35s cubic-bezier(1, 0.01, 0, 1.22),
        opacity 0.35s cubic-bezier(1, 0.01, 0, 1.22);
      display: flex;
      margin-right: 56px;

      img {
        height: 32px;
        width: auto;

        &:nth-of-type(1) {
          margin-right: 24px;
        }
      }
      @media all and (max-width: 700px) {
        margin-right: 12px;
        img {
          height: 20px;
        }
      }
    }

    &.showLogos {
      .logo {
        transform: translateY(0%);
        opacity: 1;
        transition: transform 0.35s cubic-bezier(1, 0.01, 0, 1.22),
          opacity 0.35s cubic-bezier(1, 0.01, 0, 1.22);
      }
    }

    @media all and (max-width: 700px) {
      img {
        height: 20px;
      }
    }
  }

  .navigation {
    align-items: center;
    display: flex;
    height: 100%;

    ul {
      align-items: center;
      display: flex;
      height: 100%;
      margin: 0;
    }
  }
}

.subheader-date-range-item {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 100%;
  list-style: none;
  margin-right: 72px;
  position: relative;

  &:after {
    bottom: 0;
    content: '';
    background: $blue;
    height: 3px;
    position: absolute;
    transition: width 0.35s cubic-bezier(1, 0.01, 0, 1.22);
    width: 0;
  }
  &:last-of-type {
    margin-right: 0;
  }

  &:hover,
  &.active {
    &:after {
      width: 100%;
    }

    a,
    div {
      color: $black;
    }
  }

  @media all and (max-width: 900px) {
    margin-right: 40px;

    font-size: $small;
    text-transform: capitalize;

    span {
      display: none;
    }
  }

  @media all and (max-width: 1150px) {
    margin-right: 40px;
    font-size: $small;
  }
}

.date-range {
  color: $blue-grey;
  color: lighten($blue-grey, 10);
  font-size: $medium;
  font-weight: 600;
  height: 100%;
  line-height: 60px;
  text-decoration: none;
  transition: color 0.35s cubic-bezier(1, 0.01, 0, 1.22);
}

.subheader-actions {
  height: 100%;
  line-height: 60px;

  button {
    background: none;
    border: none;
    cursor: pointer;
    height: 100%;
    padding: 0 16px;
    position: relative;
    min-width: 60px;

    &:after {
      background: $whitish;
      border: 1px solid #e3e9f3;
      bottom: 0;
      box-sizing: border-box;
      border-radius: 4px;
      content: 'Exportar datos';
      display: block;
      font-size: $xsmall;
      left: 50%;
      opacity: 0;
      padding: 4px;
      position: absolute;
      text-align: center;
      transform: translate(-50%, 120%);
      transition: opacity 0.35s ease-in, visibility 0.35s ease-in;
      width: fit-content;
      height: fit-content;
      visibility: hidden;
    }
    &:hover {
      &:after {
        opacity: 1;
        transition-delay: 0.15s;
        visibility: visible;
      }
    }

    &:first-child {
      border-left: 1px solid darken($blue-light, 4);
      border-right: 1px solid darken($blue-light, 4);

      &:after {
        content: 'Compartir vista';
      }
    }

    &:last-child {
      border-right: 1px solid darken($blue-light, 4);
    }
  }

  @media all and (max-width: 920px) {
    background: $whitish;
    bottom: 0;
    border-top-left-radius: 6px;
    box-shadow: 0 0px 16px 0 darken($blue-light, 7);
    height: 60px;
    position: fixed;
    right: 0;

    button {
      &:first-child {
        border-left: 0;
        border-right: 1px solid darken($blue-light, 4);
        border-top-left-radius: 6px;
      }

      &:last-child {
        border-right: 0;
      }
    }
  }
}

.subheader-message {
  display: none;
  &.active{
    @keyframes slidein {
      from {
        opacity:0;
        margin-top: -2%;
      }
      to {
        opacity: 1;
        margin-top: 0%;
      }
    }
    animation: slidein 0.25s ease-in forwards;
    display: block;
    right: 0;
    position: absolute;
    padding: 16px;
  }
}