@import "../../../styles/colors.scss";
@import "../../../styles/fontSizes.scss";

.text-button {
    background: $blue;
    &.disabled{
        background: $blue-alt;
    }
    border: none;
    border-radius: 20px;
    color: #FFF;
    cursor: pointer;
    font-size: $small;
    font-weight: 600;
    padding: 8px 24px;
    min-height: 40px;
    transition: transform 0.35s cubic-bezier(0.5, 0.01, 0, 3), box-shadow 0.35s cubic-bezier(0.5, 0.01, 0, 3);

    &:hover {
        cursor: unset;
        transform: scale(1.05);
        box-shadow: 0 3px 3px 0 rgba(darken($blue, 15), 0.5);
    }
    &.disabled:hover{
        transform: none;
        box-shadow: none;
    }
}
