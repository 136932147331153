@import "../../styles/colors.scss";

.company-initials {
    background: rgba($blue, 0.25);
    width: 5rem;
    height: 5rem;
    border-radius: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.company-logo {
    width: 5rem;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}