@import "../../../styles/colors.scss";
@import "../../../styles/fontSizes.scss";

.category-check {
    margin-bottom: 56px;

    .category-title {
        display: block;
        font-size: $medium;
        font-weight: 600;
        margin-bottom: 40px;
    }

    .category-check-content {
        max-height: 23rem;
        overflow-y: auto;
        margin-bottom: 2.4rem;

        &::-webkit-scrollbar-track {
            background-color: $blue-light;
            border-radius: 4px;
            box-shadow: inset 0 0 6px darken(rgba($blue,0.25), 10);
        }

        &::-webkit-scrollbar {
            width: 6px;
            background-color: #F5F5F5;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $blue;
            border-radius: 4px;
        }

        .selectedItems {
            .item {
                padding: 0.5rem;
                background-color: #044deb;
                color: white;
                border-radius: 0.5rem;
                width: 80%;
                text-align: center;
                margin-bottom: 0.5rem;
            }
        }
        
        .search {
            display: flex;
            flex-direction: column;
    
            .search-form {
                font-size: 1em;
                margin: 0.5rem 0 1rem;
                padding: 0.5rem;
                max-width: 80%;
            }
        }

    }

   

    .category-footer{
        display:flex;
        flex-direction: column;
        align-items: flex-start;
        button {
            padding-bottom: 8px;
            background: none;
            border: none;
            color: $blue;
            font-size: $medium;
            font-weight: 500;
        }
    }
}