@import "../../../styles/colors.scss";

.icon-button {
    background: darken($blue-light,5);
    border: none;
    border-radius: 6px;
    cursor: pointer;
    height: 100%;
    transition: transform 0.35s cubic-bezier(0.5, 0.01, 0, 3), box-shadow 0.35s cubic-bezier(0.5, 0.01, 0, 3);
    min-width: 40px;
    max-height: 40px;

    &:hover {
        transform: scale(1.1);
        box-shadow: 0 3px 3px 0 rgba(darken($blue, 15), 0.5);
    }
    &.--dark{
        background: darken($black ,5);
        &:hover {
            box-shadow: 0 3px 3px 0 rgba(darken($whitish, 15), 0.5);
        }
    }
}
