@import '../../styles/colors.scss';
@import "../../styles/fontSizes.scss";

.home {
  display: flex;
  justify-content: space-between;
  padding-top: 56px;
  flex-wrap: wrap;
  flex-direction: row;
  @media all and (max-width: 900px) {
    flex-direction: column;
  }
  .categories-container {
    width: 21%;
    @media all and (max-width: 900px) {
      width: 100%;
      order: 3;
    }
  }

  .area-center,
  .area-right {
    strong.title {
      animation: appear 0.25s ease-in forwards;
      display: block;
      font-size: $big;
      font-weight: 600;
      margin-bottom: 16px;
      opacity: 0;
      @keyframes appear {
        to{
          opacity: 1;
        }
      }

      span {
        display: inline-block;
        position: relative;
        padding-left: 8px;
      }
    }
  }

  .area-center {
    position: relative;
    max-height: 976px;
    
    @media all and (max-width: 900px) {
      
      order: 1;
    }
  }

  .area-right {

    margin-bottom: 60px;

    .top-manager {
      display: flex;
      justify-content: space-between;

      .icons-view-type {
        display: flex;
        justify-content: space-between;

        .icon {
          cursor: pointer;
          padding-left: 15px;
          position: relative;
          height: 16px;

          &:after {
            background: #FFF;
            border: 1px solid #e3e9f3;
            bottom: 0;
            box-sizing: border-box;
            border-radius: 4px;
            content: 'Vista cuadrícula';
            font-size: 12px;
            display: block;
            left: 50%;
            opacity: 0;
            padding: 4px;
            position: absolute;
            transform: translate(-50%, 120%);
            transition: opacity 0.35s ease-in, visibility 0.35s ease-in;
            text-align: center;
            width: fit-content;
            height: fit-content;
            visibility: hidden;
          }

          &:hover {
            &:after {
              opacity: 1;
              transition-delay: 0.15s;
              visibility: visible;
            }
          }

          &:last-of-type{
            &:after{
              content: 'Vista listado';
            }
          }
        }
      }
    }

    .title.small {
      display: inline-flex;
      font-size: $medium;

      span {
        color: $blue;
        position: relative;

        img {
          display: inline-block;
          padding-left: 8px;
          vertical-align: middle;
        }

        ul {
          background: #FFF;
          border-radius: 6px;
          box-shadow: 0 5px 10px rgba(lighten($blue-grey, 10), 0.2);
          padding: 0px;
          position: absolute;
          left: 0;
          max-height: 0;
          overflow: hidden;
          transform: translateY(16px);
          transition: max-height 0.35s ease-in;
          width: 150%;
          z-index: 2;

          li {
            border-bottom: 1px solid rgba($blue-grey, 0.2);
            cursor: pointer;
            font-size: $medium;
            list-style: none;
            padding: 16px;
            transition: background-color 0.25s ease-in;

            &:last-of-type {
              border-bottom: 0;
            }

            &:hover {
              background-color: $blue-light;
            }
          }
        }

        &:hover {
          ul {
            max-height: 300px;
          }
        }
      }
    }

    @media all and (max-width: 900px) {
      
      order: 2;
    }
  }

  .subtitle {
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
  .subtitle-partial {
    color: $blue;
    font-size: $big;
    font-weight: 600;
  }
  .subtitle-total {
    font-size: $medium;
    color: $blue-grey;
    margin-left: 15px;
  }
}