@import "../../styles/colors.scss";
@import "../../styles/fontSizes.scss";

.modal {
  &.--dark {
    .content-modal {
      background-color: #041945;

      h3 {
        color: $whitish;
      }

      .requirements {
        ul {
          li {
            color: $whitish
          }
        }
      }
    }
  }

  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(darken($black, 10), 0.85);
  transition: visibility 0.35s ease-in,
  opacity 0.35s ease-in;
  z-index: 1000;

  .overlay {
    height: 100%;
    width: 100%;
  }

  .close {
    color: $whitish;
    text-align: right;
    font-size: 30px;
    width: 40px;
    height: 40px;
    position: fixed;
    right: 40px;
    top: 40px;
    display: inline-block;

    &:after,
    &:before {
      background: $whitish;
      content: '';
      width: 100%;
      height: 4px;
      position: absolute;
      left: 50%;
      top: 50%;
    }

    &:before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &:after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  .content-modal {
    align-items: flex-start;
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0.8);
    padding: 96px 56px 56px 56px;
    background: $whitish;
    opacity: 0;
    visibility: hidden;
    transition: transform 0.35s cubic-bezier(0.5, 0.01, 0, 3), visibility 0.35s ease-in, opacity 0.35s ease-in;
    transform-origin: center;
    justify-content: space-between;
  }


  &.opened {
    opacity: 1;
    visibility: visible;

    .content-modal {
      opacity: 1;
      transform: scale(1) translate(-50%, -50%);
      transform-origin: center;
      visibility: visible;
    }
  }
}