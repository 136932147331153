@import "../../styles/colors.scss";
@import "../../styles/fontSizes.scss";

.change-password {
  display: flex;
  flex-direction: column;
  min-width: 28rem;
  h2{
    padding-bottom: 2.4rem;
  }
  h4{
    margin-bottom: 3.2rem;
  }
  label{
    padding-bottom: 1.6rem;
  }
  input {
    width: 16rem;
    margin-bottom: 3.2rem;
    padding: 8px;
    border: 1px solid $blue-alt
  }
}
.error{
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  display: flex;
  justify-content: center;
  background: lightgoldenrodyellow;
  line-height: 3;
  margin-bottom: 1.2rem;
}