@import "../../styles/colors.scss";
@import "../../styles/fontSizes.scss";

.map-selector-footer {
    position: absolute;
    right: 0;
    bottom: 0;
    box-sizing: border-box;
    padding: 16px 40px 40px 40px;
    
    .map-selector-summary {
        display: flex;
        flex-flow: column;

        strong {
            font-size: $xbig;
            font-weight: 600;
        }

        span {
            color: $blue-grey;
            font-size: 18px;
            font-weight: 600;
            padding-top: 8px;
        }
    }
    
    .map-selector-nav {
        align-items: center;
        display: flex;
        justify-content: flex-end;
    }    
    .map-selector-nav-row {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-bottom: 8px;
        button {
            font-size: $medium;
            font-weight: 600;
            width: 25px;
            height: 25px;
            background: none;
            border: none;
            color: $blue;
            border-radius: 5px;
            background-color: $blue-light;
            &:not(:last-child) {
                margin-right: 8px;
            }
        }
    
        &.middle-row {
            justify-content: space-between;
        }
    }  
    .map-selector-directions{
        width: 25 * 3 + 8 * 2px;
        margin-right: 44px;
    }

    button{
        cursor: pointer;
    }
}
