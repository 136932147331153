.pagination {
    display: flex;
    list-style: none;
    padding-bottom: 16px;
    li{
        padding: 8px;
        margin-right: 12px;
    }
    .pagination-button {
        border: 1px solid #e3e9f3;

        cursor: pointer;
    }
}