@import "../../styles/colors.scss";
@import "../../styles/fontSizes.scss";

.job-modal {
  &.--dark {
    .content-modal {
      background-color: #041945;

      h3 {
        color: $whitish;
      }

      .requirements {
        ul {
          li {
            color: $whitish
          }
        }
      }
    }
  }

  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(darken($black, 10), 0.85);
  transition: visibility 0.35s ease-in,
  opacity 0.35s ease-in;
  z-index: 1000;

  
  .closed {
    position: absolute;
    top: 0;
    left: 0;
    padding: 5px;
    font-size: 13px;
    font-style: italic;
    background: #dedede;
  }

  
  &.--dark {
    .closed {
      color: $black;
    }
  }


  .overlay {
    height: 100%;
    width: 100%;
  }

  .close {
    color: $whitish;
    text-align: right;
    font-size: 30px;
    width: 40px;
    height: 40px;
    position: fixed;
    right: 40px;
    top: 40px;
    display: inline-block;

    &:after,
    &:before {
      background: $whitish;
      content: '';
      width: 100%;
      height: 4px;
      position: absolute;
      left: 50%;
      top: 50%;
    }

    &:before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &:after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  .content-modal {
    align-items: flex-start;
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0.8);
    padding: 96px 56px 56px 56px;
    background: $whitish;
    opacity: 0;
    visibility: hidden;
    transition: transform 0.35s cubic-bezier(0.5, 0.01, 0, 3), visibility 0.35s ease-in, opacity 0.35s ease-in;
    transform-origin: center;
    justify-content: space-between;

    // Those are a bit arbitrary
    min-width: 65vw;
    max-width: 800px;

    .company-logo {
      background: white;
      border: 4px solid $whitish;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 114px;
    }

    h3 {
      color: $black;
      font-size: $big;
      font-weight: 600;
      text-align: left;
      margin-bottom: 56px;
    }

    p {
      color: #727272;
      font-size: $medium;
      font-weight: 500;
      line-height: 1.8;
    }

    .content-modal-inner {
      display: flex;
      flex-flow: row;
      overflow-y: scroll;
      height: 24rem;

      &::-webkit-scrollbar-track {
          background-color: $blue-light;
          border-radius: 4px;
          box-shadow: inset 0 0 6px darken(rgba($blue,0.25), 10);
      }

      &::-webkit-scrollbar {
          width: 6px;
          background-color: #F5F5F5;
      }

      &::-webkit-scrollbar-thumb {
          background-color: $blue;
          border-radius: 4px;
      }
    }

    .description {
      box-sizing: border-box;
      padding-right: 32px;
      width: 60%;
    }

    .requirements {
      border-left: 1px solid #E5E9F2;
      padding-left: 7%;
      top: 0;
      width: 30%;

      h3 {
        margin-bottom: 40px;
      }

      ul {
        list-style-type: none;
        margin-bottom: 40px;

        li {
          color: $black;
          font-weight: 500;
          padding-bottom: 16px;

          &:before {
            content: "\25E6";
            color: $blue;
            font-weight: bold;
            display: inline-block;
            width: 1em;
            margin-left: -1em;
          }
        }
      }

      footer {
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;
        margin-top: 0;

        span {
          background: #E5E9F2;
          border-radius: 6px;
          box-sizing: border-box;
          color: $blue-alt;
          font-size: $medium;
          font-weight: 500;
          padding: 16px;
          text-align: center;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 50%;
          overflow: hidden;
        }
      }
    }
  }

  .share-message {
    background: white;
    font-size: 13px;
    margin-top: 10px;
    margin-bottom: 10px;
    display: none;
    &.visible {
      display: block;
    }
  }


  &.opened {
    opacity: 1;
    visibility: visible;

    .content-modal {
      opacity: 1;
      transform: scale(1) translate(-50%, -50%);
      transform-origin: center;
      visibility: visible;
    }
  }
}